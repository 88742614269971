#cac-index {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 20px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

#cac-index > a {
  text-decoration: none;
}

.cac-container {
  border: 1px solid #0000001e;
  border-radius: 4px;
  transition: .2s all;
  display: grid;
  grid-template-columns: 60px 1fr;
  padding: 10px 0;
  cursor: pointer;
}

.cac-container:hover {
  border: 1px solid #00000082;
  transition: .2s all;
}

.cac-text-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
}

.cac-icon {
  display: grid;
  justify-content: center;
  align-items: center;
}

.cac-text-title {
  background: -webkit-linear-gradient(135deg, #8e2de2, #4a00e0);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cac-text-subtitle {
  color: #464646;
}

@media only screen and (max-width: 500px) {
  #cac-index { 
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 20px 20px;
  }
}