.App-header__mini {
  flex: 1 0 auto;
  width: 100%;
  min-height: 20px;
  max-height: 20px;
  background: linear-gradient(135deg, #8e2de2, #4a00e0);
}

.App-header__max {
  height: 70vh;
  background: linear-gradient(135deg, #8e2de2, #4a00e0);
  border-bottom-left-radius: 100% 10%;
  border-bottom-right-radius: 100% 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

#header__title {
  font-weight: 700;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-template-areas: 'text image';
  justify-content: center;
  align-items: center;
  font-size: 1.6em;
}

#header__title__text {
  grid-area: text;
}

_::-webkit-full-page-media, _:future, :root #header__hoppinger-logo--safari_only {
  grid-area: image;
  width: 140px;
  min-width: min-content;
  height: 56px;
  margin-top: -6px;
  margin-left: 12px;
}

.header__hoppinger-logo {
  grid-area: image;
  height: 56px;
  margin-top: -6px;
  margin-left: 12px;
}

@media only screen and (max-width: 500px) {
  #header__title {
    font-size: 1.3em;
  }
  
  .header__hoppinger-logo {
    grid-area: image;
    width: min-content;
    height: 50px;
    margin: -5px -70px 0 -70px;
  }
}

@media only screen and (max-width: 440px) {
  #header__title {
    font-size: 1.2em;
  }
  
  .header__hoppinger-logo {
    grid-area: image;
    width: 150px;
    height: 40px;
    margin: -5px -10px 0 -10px;
  }
}

#header__date {
  margin-bottom: 50px;
}

#header__button {
  border: none;
  background-color: white;
  color: black;
  border-radius: 500px;
  padding: 10px 20px;
  font-size: 1.2em;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}

#header__profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top:50px;
}

#header__image {
  border-radius: 50%;
  width: 75px;
  margin-bottom: 10px;
}

#header__name {
  font-weight: 700;
}