.people--container {
  background: linear-gradient(135deg, #8e2de2, #4a00e0);
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  padding: 40px 5px;
  overflow: auto;
  margin-top: 10px;
}

.person--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 195px;
}

.person--image {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin-bottom: 20px
}

.person--name {
  font-weight: 700;
}

.person--function {
  font-size: .9em;
}