#competence-index {
  margin-bottom: 50px;
}

.competence-group {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 40px 1fr;
  margin-bottom: 20px;
}

.competence-group-name {
  font-weight: 700;
}

.card-container { 
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 30px 30px;
}

@media only screen and (max-width: 500px) {
  .card-container { 
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 30px 30px;
  }
}

@media only screen and (max-width: 350px) {
  .card-container { 
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 30px 30px;
  }
}