.notice-container {
  border-radius: 4px;
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-rows: 1fr;
  padding: 10px;
  margin-bottom: 20px;
  align-items: center;
}

.notice-icon-container {
  display: grid;
  justify-content: center;
  align-items: center;
}

.notice-info {
  background-color: #e3f2fd;
  border: 1px solid #1976d2;
}

.notice-help {
  background-color: #e3f2fd;
  border: 1px solid #1976d2;
}

.notice-warning {
  background-color: #fff3e0;
  border: 1px solid #f57c00;
}

.notice-error {
  background-color: #ffebee;
  border: 1px solid #d32f2f;
}