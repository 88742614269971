#competence-container > section {
  margin-top: 50px !important;
}

#competence-container > section > h1 {
  font-size: 2em;
  line-height: 40px;
  margin-bottom: 10px;
}

#competence-container > section > h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

#competence-back-button-container {
  margin-left: 38px;
}

#competence-back-button {
  margin-right: 5px;
}

.competence-info-container {
  margin-top: 20px;
  border: 1px solid #8e2de2;
  background-color: rgba(142, 45, 226, .2);
  border-radius: 4px;
  padding: 10px;
}

.competence-info-container > span{
  display: block;
}

#competence-info-group {
  font-weight: 700;
}

#competence-c-title {

}


@media only screen and (max-width: 500px) {
  #competence-container > section {
    margin-top: 20px !important;
  }

  #competence-back-button-container {
    margin-left: 10px;
  }

  #competence-container > section > h1 {
    font-size: 1.5em;
    line-height: 30px;
    margin-bottom: 10px;
  }

  #competence-container > section > h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
}