.cc-container {
  border: 1px solid #0000001e;
  border-radius: 4px;
  transition: .2s all;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 3px;
  text-decoration: none;
}

.cc-container:hover {
  border: 1px solid #00000082;
  transition: .2s all;
}

.cc-container > span {
  display: block;
  background: -webkit-linear-gradient(135deg, #8e2de2, #4a00e0);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}