.ratio-container {
  position: relative;
  margin: 0;
}

.ratio-container:after {
  content: '';
  display: block;
  height: 0;
  width: 100%;
  /* 16:9 = 56.25% = calc(9 / 16 * 100%) */
  padding-bottom: 56.25%;
  content: "";
}

.ratio-container-main-map:after {
  padding-bottom: 45%;
}

.ratio-container-ad1-flow:after {
  padding-bottom: 44%;
}

.ratio-container-poc-new-user:after {
  padding-bottom: 55%;
}

.ratio-container-poc-all-users:after {
  padding-bottom: 55%;
}

.ratio-container-trello-board:after {
  padding-bottom: 64%;
}

.ratio-container-sd-0:after {
  padding-bottom: 63.25%;
}

.ratio-container-sd-1:after {
  padding-bottom: 71.25%;
}

.ratio-container-sd-2:after {
  padding-bottom: 72.25%;
}

.ratio-container-sd-3:after {
  padding-bottom: 72.25%;
}

.ratio-container-code-comparison:after {
  padding-bottom: 58.25%;
}

.ratio-container-worked-hours:after {
  padding-bottom: 11.25%;
}

.ratio-container > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* unknown ration variation */
.unknown-ratio-container > * {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.image-caption {
  font-size: .8em;
  display: block;
  text-align: center;
  font-style: italic;
  margin-bottom: 20px;
}