.App {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
  text-align: center;
  padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
  padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
}

section {
  display: block;
  margin: 0 auto;
  margin-top: 50px;
  max-width: 900px;
}

section > * {
  margin: 0 50px;
}

section > p {
  margin-bottom: 25px;
}

/* main > section > div {
  margin: 0 10px !important;
} */

.section-img, .lazyload, .lazyloading {
  margin: 0;
  width: 100%;
  max-width: 900px;
  height: auto;
}

p > code {
  background-color: rgba(27,31,35,.05);
  border-radius: 3px;
  font-size: 85%;
  padding: .2em .4em;
  margin: 0;
}

@media only screen and (max-width: 800px) {
  section {
    display: block;
    margin: 50px 10px 0 10px;
    max-width: 800px;
  }

  /* main > section > div {
    margin: 0 !important;
  } */
}

@media only screen and (max-width: 500px) {
  section {
    display: block;
    margin: 20px 10px 0 10px;
    max-width: 800px;
  }

  /* main > section > div {
    margin: 0 !important;
  } */

  section > * {
    margin: 0 5px;
  }
}

h1, h2, h3 {
  background: -webkit-linear-gradient(135deg, #8e2de2, #4a00e0);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blur-up {
  -webkit-filter: blur(5px);
  filter: blur(5px);
  transition: filter 400ms, -webkit-filter 400ms;
}

.blur-up.lazyloaded {
  -webkit-filter: blur(0);
  filter: blur(0);
}